import React from "react"
import "./Points.scss"

const Points = () => {
  return (
    <div className="points">
      <div className="container">
        <div className="points__wrapper">
          <div className="points__title">
            Mit Ihrer neuen METRO FS Mastercard® die Königsklasse hautnah
            erleben!
          </div>
          <div className="points__subtitle">
            Beantragen Sie zwischen dem 20. Oktober und dem 31. Dezember 2022
            die kostenlose METRO FS Mastercard® und sichern Sie sich Ihre Chance
            auf eine von 4 Reisen zu einem Achtelfinalspiel der UEFA Champions
            League!
          </div>
          <div className="points__points-title">
            Die Hauptgewinner und Ihre Begleitung dürfen sich freuen auf:
          </div>
          <div className="points__points-wrapper">
            <div className="points__points-container">
              <div className="points__point">
                Eine Einladung zum Heimspiel einer deutschen Mannschaft im
                Frühjahr 2023
              </div>
              <div className="points__point">
                Zwei Übernachtungen im 4-Sterne-Hotel
              </div>
              <div className="points__point">Ein gemeinsames Abendessen</div>
            </div>
            <div className="points__points-container">
              <div className="points__point">
                Einen Gutschein für die Hin- und Rückreise
              </div>
              <div className="points__point">
                ... und einen Fan-Moment der Extraklasse
              </div>
              <div className="points__point-text">
                Weitere 11 Gewinner erhalten jeweils ein Amazon Prime
                Jahres-Abo, um Fußballspiele, Filme, Serien und vieles mehr zu
                streamen.
              </div>
            </div>
          </div>
          <div className="points__small-text">
            Die Preise werden zur Verfügung gestellt von Mastercard, offizieller
            Sponsor der UEFA Champions League.
          </div>
        </div>
      </div>
    </div>
  )
}
export default Points
