import React from "react"
import "./CheckPoints.scss"
import { StaticImage } from "gatsby-plugin-image"
import MasterCardButton from "../MasterCardButton/MasterCardButton"

const CheckPoints = () => {
  return (
    <div className="check-points">
      <div className="container">
        <div className="check-points__wrapper">
          <div className="check-points__image">
            <StaticImage src="./assets/master-card.png" alt="master-card" />
          </div>
          <div className="check-points__points-wrapper">
            <div className="check-points__points-title">
              Ihre Kartenvorteile auf einen Blick
            </div>
            <div>
              <div className="check-points__point-container">
                <div>
                  <div className="check-points__point-title">1% Cashback </div>
                  <div className="check-points__point-subtitle">
                    Sie erhalten bei jedem Einkauf mit der METRO FS Mastercard®
                    Geld zurück, weltweit überall, wo Mastercard® akzeptiert
                    wird.{" "}
                  </div>
                </div>
              </div>
              <div className="check-points__point-container">
                <div>
                  <div className="check-points__point-title">
                    50 € Startguthaben
                  </div>
                  <div className="check-points__point-subtitle">
                    Einmal mit der METRO FS Mastercard® einkaufen und 50 €
                    Startguthaben erhalten
                  </div>
                </div>
              </div>
              <div className="check-points__point-container">
                <div>
                  <div className="check-points__point-title">
                    Ohne Kontowechsel
                  </div>
                  <div className="check-points__point-subtitle">
                    Verbinden Sie die Karte ganz einfach mit Ihrem aktuellen
                    Bankkonto und los geht’s!
                  </div>
                </div>
              </div>
            </div>
            <MasterCardButton showInDesktop />
          </div>
        </div>
      </div>
    </div>
  )
}
export default CheckPoints
