import React from "react"
import "./MasterCardTeaser.scss"
import { StaticImage } from "gatsby-plugin-image"
import MasterCardButton from "../MasterCardButton/MasterCardButton"

const MasterCardTeaser = () => {
  return (
    <div className="mastercard-teaser">
      <div className="container">
        <div className="mastercard-teaser__wrapper">
          <div className="mastercard-teaser__image">
            <StaticImage src="./assets/champions.png" alt="champions-league" />
          </div>
          <div className="mastercard-teaser__title">
            Live bei der UEFA Champions League - mit Ihrer neuen METRO FS
            Mastercard®
          </div>
        </div>
        <MasterCardButton showInDesktop />
      </div>
    </div>
  )
}
export default MasterCardTeaser
