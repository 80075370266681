import React from "react"
import UniversalLink from "../../../../UniversalLink"
import "./MasterCardButton.scss"

const MasterCardButton = ({ showInDesktop }) => {
  return (
    <div className={`master-card-button ${showInDesktop ? "" : "is--default"}`}>
      <UniversalLink
        target="_blank"
        to="https://get.metro-fs.com/"
        className="button is--orange"
      >
        Karte beantragen und teilnehmen
      </UniversalLink>
    </div>
  )
}
export default MasterCardButton
