import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../../layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Points from "./blocks/Points/Points"
import CheckPoints from "./blocks/CheckPoints/CheckPoints"
import MasterCardTeaser from "./blocks/MasterCardTeaser/MasterCardTeaser"
import MasterCardButton from "./blocks/MasterCardButton/MasterCardButton"

const MasterCardPage = ({ data }) => {
  const { wpPage: page } = data

  return (
    <Layout
      menuColor={page.whitemenucheckbox.whiteMenu}
      hideNavigation={page.whitemenucheckbox.hideNavigation}
      hideButton={page.whitemenucheckbox.hideButton}
      cleanFooter={page.whitemenucheckbox.cleanFooter}
      whiteBackgroundMenu={page.whitemenucheckbox.whiteBackgroundMenu}
      hideBanner={page.ACFBanner.hideBanner}
    >
      <Seo post={page} />
      <div className="page page-mastercard">
        <MasterCardTeaser />
        <Points />
        <CheckPoints />
        <MasterCardButton />
      </div>
    </Layout>
  )
}

MasterCardPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MasterCardPage

export const pageQuery = graphql`
  query PageMasterCardById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      nodeType
      whitemenucheckbox {
        whiteMenu
        hideNavigation
        hideButton
        cleanFooter
        whiteBackgroundMenu
      }
      ACFBanner {
        hideBanner
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
